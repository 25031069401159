<template>
    <header>
        <nav
            class="navbar bg-white py-3 md:py-4 fixed w-full px-0"
            :class="{ 'z-50': !isPrimaryMenuOpen, 'z-20': isPrimaryMenuOpen }"
        >
            <div class="container relative flex items-center justify-between">
                <NuxtLink
                    href="/"
                    @click="handleAccountLogoClick"
                >
                    <h1
                        class="account-name flex items-center text-lg md:text-xl font-semibold"
                        data-test="header-account-name"
                    >
                        <img
                            v-if="logo"
                            class="account-logo h-[40px] md:h-[60px] mr-2"
                            :src="logo"
                            alt=""
                        >
                        {{ name }}
                    </h1>
                </NuxtLink>
                <div class="flex items-center">
                    <div class="flex gap-5 font-semibold">
                        <NuxtLink
                            id="subscribe"
                            class="btn btn-primary hidden md:block"
                            :href="registrationLink"
                            target="_blank"
                            rel="noopener external"
                        >
                            <span class="text-sm">{{ $t('header.subscribe') }}</span>
                        </NuxtLink>
                        <button
                            id="user-menu"
                            class="btn btn-dropdown md:hidden"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="handleUserMenuButtonClick"
                        >
                            <icon-menu-user class="icon-extra-large" />
                        </button>
                    </div>
                </div>
                <div
                    v-if="showUserDropdownMenu"
                    class="dropdown-menu user-dropdown-menu flex flex-col gap-4 rounded-md bg-white opacity-1 absolute p-6 top-[70px] md:top-[90px]"
                >
                    <NuxtLink
                        id="subscribe"
                        class="btn btn-primary"
                        :href="registrationLink"
                        target="_blank"
                        rel="noopener external"
                    >
                        <span class="text-sm">{{ $t('header.subscribe') }}</span>
                    </NuxtLink>
                </div>
            </div>
        </nav>
    </header>
</template>

<script setup lang="ts">
    import { ref, watch, onUnmounted } from 'vue';
    import IconMenuUser from '~/components/icons/IconMenuUser.vue';
    import { closeModalIfClickedOutside } from '~/utils/helpers';
    import { useAccountStore } from '~/stores/accountStore';
    import { useNavigationStore } from '~/stores/navigationStore';

    defineProps({
        isPrimaryMenuOpen: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const navigationStore = useNavigationStore();
    const { appColor, buttonColor, buttonTextColor, logo, name, registrationLink } = useAccountStore().state;
    const emit = defineEmits(['showUserDropdownMenuChanged', 'showStaticDropdownMenuChanged']);

    const showUserDropdownMenu = ref(false);

    const handleUserMenuButtonClick = () => {
        showUserDropdownMenu.value = !showUserDropdownMenu.value;
        emit('showUserDropdownMenuChanged', showUserDropdownMenu.value);
    };

    const closeUserDropDownIfClickedOutside = (event: Event) => {
        closeModalIfClickedOutside(event, showUserDropdownMenu, '.user-dropdown-menu', '#user-menu');
        emit('showUserDropdownMenuChanged', showUserDropdownMenu.value);
    };

    const handleAccountLogoClick = () => {
        const primaryNavItem = navigationStore.state.navItems[0];
        navigationStore.setActiveNavItems(primaryNavItem, 1);
        navigationStore.setPageDetails(primaryNavItem.label, primaryNavItem.description, primaryNavItem.icon);
    };

    watch(
        () => showUserDropdownMenu.value,
        showUserDropdownMenu => {
            if (showUserDropdownMenu) {
                document.addEventListener('click', closeUserDropDownIfClickedOutside);
            }
        },
    );

    onUnmounted(() => {
        document.removeEventListener('click', closeUserDropDownIfClickedOutside);
    });
</script>

<style scoped lang="scss">
    $appColor: v-bind(appColor);
    $buttonColor: v-bind(buttonColor);
    $buttonTextColor: v-bind(buttonTextColor);

    .account-name {
        color: $appColor;
        line-height: 1.5rem;
    }

    .navbar {
        box-shadow: 0 1px 20px 0 rgba(0,0,0,.1);
    }

    .btn-primary {
        border-color: $buttonColor;
        background-color: $buttonColor;
        color: $buttonTextColor;

        &:hover {
            opacity: 0.8;
        }
    }

    .btn-dropdown {
        padding: 0;
        border: none;
    }

    .dropdown-menu {
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
        left: auto;
        right: 16px;
    }

    .dropdown-item {
        transition: all 0.15s ease;
        border-bottom: 1px solid;
        @apply border-neutral-100;

        &:hover {
            @apply bg-neutral-100;
        }
    }

    .dropdown-item:first-of-type {
        @apply rounded-t-md;
    }

    .dropdown-item:last-of-type {
        @apply rounded-b-md;
    }
</style>
