<template>
    <div
        class="flex flex-row justify-end"
    >
        <div
            class="archive-btn flex items-center bg-neutral-600"
            @click.stop="showArchive"
        >
            <label
                class="archive-label cursor-pointer"
            >{{ navStore.showArchive ? $t('timeline.archive.hide') : $t('timeline.archive.show') }}</label>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useAccountStore } from '~/stores/accountStore.ts';
    import { useNavigationStore } from '~/stores/navigationStore';
    import { useRoute } from 'vue-router';

    const { buttonColor } = useAccountStore().state;
    const navStore = useNavigationStore();
    const route = useRoute();

    const showArchive = async () => {
        navStore.setShowArchive(!navStore.showArchive);
        if (navStore.showArchive) {
            await navigateTo(`${route.path}/archive`);
            return;
        }
        await navigateTo(route.path.replace('/archive', ''));
        return;
    };
</script>

<style scoped lang="scss">
    .archive-btn {
        color: #ffffff;
        border-radius: 0.4rem;
        padding: 0.3rem 0.7rem;
        background-color: v-bind(buttonColor);
        cursor: pointer;
        transition: all 0.15s ease;
        text-align: center;
        line-height: 1.1rem;

        &:hover {
            filter: brightness(110%);
        }
    }

    .archive-label {
        font-size: 0.825rem;
        font-weight: 600;
    }
</style>
