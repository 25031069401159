<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="20"
            cy="20"
            r="19"
            :stroke="buttonColor"
            stroke-width="2"
        />
        <path
            :fill="buttonColor"
            :stroke="buttonColor"
            d="M19.7467 19.6212C19.7633 19.6212 19.7799 19.6212 19.7998 19.6212C19.8064 19.6212 19.813 19.6212 19.8196 19.6212C19.8296 19.6212 19.8428 19.6212 19.8528 19.6212C20.8236 19.6047 21.6088 19.2634 22.1887 18.6107C23.4643 17.1727 23.2522 14.7076 23.229 14.4724C23.1462 12.7064 22.3112 11.8615 21.6221 11.4672C21.1085 11.1723 20.5088 11.0133 19.8395 11H19.8163C19.813 11 19.8064 11 19.8031 11H19.7832C19.4154 11 18.6931 11.0596 18.0006 11.4539C17.3048 11.8482 16.4566 12.6931 16.3738 14.4724C16.3506 14.7076 16.1386 17.1727 17.4142 18.6107C17.9907 19.2634 18.7759 19.6047 19.7467 19.6212ZM17.2584 14.5552C17.2584 14.5452 17.2618 14.5353 17.2618 14.5287C17.3711 12.153 19.0576 11.8979 19.7799 11.8979H19.7931C19.7998 11.8979 19.8097 11.8979 19.8196 11.8979C20.7142 11.9178 22.235 12.2823 22.3378 14.5287C22.3378 14.5386 22.3378 14.5486 22.3411 14.5552C22.3444 14.5784 22.5763 16.8314 21.5227 18.0176C21.1052 18.4881 20.5486 18.72 19.8163 18.7266C19.8097 18.7266 19.8064 18.7266 19.7998 18.7266C19.7931 18.7266 19.7898 18.7266 19.7832 18.7266C19.0543 18.72 18.4943 18.4881 18.0802 18.0176C17.0298 16.8381 17.2551 14.5751 17.2584 14.5552Z"
        />
        <path
            :fill="buttonColor"
            :stroke="buttonColor"
            d="M26.6087 25.7101C26.6087 25.7068 26.6087 25.7035 26.6087 25.7002C26.6087 25.6737 26.6054 25.6472 26.6054 25.6174C26.5855 24.9613 26.5424 23.4273 25.1044 22.9369C25.0945 22.9336 25.0812 22.9303 25.0713 22.927C23.577 22.5459 22.3345 21.6845 22.3212 21.6745C22.1191 21.5321 21.8408 21.5818 21.6983 21.7839C21.5559 21.986 21.6056 22.2643 21.8077 22.4068C21.864 22.4465 23.1827 23.3643 24.8327 23.7884C25.6047 24.0634 25.6909 24.8884 25.7141 25.6439C25.7141 25.6737 25.7141 25.7002 25.7174 25.7267C25.7207 26.0249 25.7008 26.4855 25.6478 26.7505C25.1111 27.0553 23.0071 28.109 19.8064 28.109C16.619 28.109 14.5018 27.052 13.9618 26.7472C13.9087 26.4821 13.8856 26.0216 13.8922 25.7234C13.8922 25.6969 13.8955 25.6704 13.8955 25.6406C13.9187 24.8851 14.0048 24.0601 14.7768 23.7851C16.4269 23.361 17.7456 22.4399 17.8019 22.4035C18.004 22.261 18.0537 21.9827 17.9112 21.7806C17.7688 21.5784 17.4904 21.5287 17.2883 21.6712C17.2751 21.6812 16.0392 22.5426 14.5383 22.9236C14.525 22.927 14.5151 22.9303 14.5051 22.9336C13.0672 23.4273 13.0241 24.9613 13.0042 25.6141C13.0042 25.6439 13.0042 25.6704 13.0009 25.6969C13.0009 25.7002 13.0009 25.7035 13.0009 25.7068C12.9976 25.8791 12.9943 26.7638 13.1699 27.2078C13.203 27.2939 13.2627 27.3668 13.3422 27.4165C13.4416 27.4828 15.8238 29.0003 19.8098 29.0003C23.7957 29.0003 26.1779 27.4794 26.2773 27.4165C26.3535 27.3668 26.4165 27.2939 26.4496 27.2078C26.6153 26.7671 26.612 25.8824 26.6087 25.7101Z"
        />
    </svg>
</template>

<script setup>
    import { useAccountStore } from '~/stores/accountStore.ts';

    const { buttonColor } = useAccountStore().state;
</script>
