<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 18L18 2"
            :stroke="buttonColor"
            stroke-width="2.5"
            stroke-linecap="round"
        />
        <path
            d="M2 2L18 18"
            :stroke="buttonColor"
            stroke-width="2.5"
            stroke-linecap="round"
        />
    </svg>
</template>

<script setup>
    import { useAccountStore } from '~/stores/accountStore.ts';

    const { buttonColor } = useAccountStore().state;
</script>
