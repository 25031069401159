<template>
    <div
        v-if="pageTitle && showDescription"
        class="pt-3 mb-7"
    >
        <div
            v-if="!navigationStore.isHiddenHeader"
            class="flex items-center flex-row justify-between"
        >
            <div class="flex items-center flex-row">
                <span
                    v-if="pageIcon"
                    class="page-icon mr-3"
                    v-html="pageIcon"
                />
                <h3
                    class="page-title"
                >
                    {{ pageTitle }}
                    <label v-if="navigationStore.showArchive">
                        - {{ $t('timeline.archive.label') }}
                    </label>
                </h3>
            </div>
            <archive-button
                v-if="showArchiveFilter"
            />
        </div>
        <p
            v-if="pageDescription"
            class="page-description text-neutral-500 pt-4 pb-4 overflow-hidden"
            data-test="page-description"
        >
            <custom-page-description :description="pageDescription" />
        </p>
    </div>
    <hr
        v-if="showDivider && !navigationStore.isHiddenHeader"
        class="mb-7"
        data-test="page-divider"
    >
    <archive-button
        v-if="showArchiveFilter && navigationStore.isHiddenHeader"
        class="archive-filter"
    />
</template>

<script setup lang="ts">
    import CustomPageDescription from '~/components/common/CustomPageDescription.vue';
    import { computed } from 'vue';
    import { EMenuType } from '~/types/enums';
    import { useNavigationStore } from '~/stores/navigationStore';
    import ArchiveButton from '~/components/timeline/ArchiveButton.vue';

    defineProps({
        pageTitle: {
            type: String,
            required: true,
        },
        pageDescription: {
            type: String,
            required: true,
        },
        pageIcon: {
            type: String,
            default: null
        },
    });

    const navigationStore = useNavigationStore();

    const showDivider = computed(() => {
        if (!navigationStore.getLastActiveNavItem) {
            return false;
        }

        const hasLastActiveNavItem = Object.keys(navigationStore.getLastActiveNavItem).length > 0;
        const isCustomOrMenuCollectionPage = ![EMenuType.CUSTOM_PAGE, EMenuType.MENU_COLLECTION].includes(navigationStore.getLastActiveNavItem.type);

        return hasLastActiveNavItem && isCustomOrMenuCollectionPage;
    });

    const showDescription = computed(() => {
        if (!navigationStore.getLastActiveNavItem) {
            return true;
        }

        const hasRequiredDescription = [EMenuType.CUSTOM_PAGE].includes(navigationStore.getLastActiveNavItem.type);

        return hasRequiredDescription || !navigationStore.isHiddenHeader;
    });

    const showArchiveFilter = computed(() => {
        if (!navigationStore.getLastActiveNavItem) {
            return false;
        }

        return [EMenuType.POSTS_PAGE].includes(navigationStore.getLastActiveNavItem.type)
            && navigationStore.getLastActiveNavItem.hasArchive;
    });
</script>

<style scoped lang="scss">
    .archive-filter {
        margin-bottom: 1.2rem;
    }
</style>
