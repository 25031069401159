<template>
    <Transition name="slide-up">
        <div
            v-if="showModal"
            class="modal-overlay"
            data-test="modal-overlay"
            @click="closeModal"
        >
            <div
                key="modal"
                class="modal bg-white rounded-md p-2 md:w-2/2"
                data-test="modal-content"
                @click.stop=""
            >
                <image-slider
                    v-if="imagePaths.length > 1"
                    :image-paths="imagePaths"
                    :max-height="650"
                    :arrow-navigation-allowed="true"
                />
                <img
                    v-else-if="imagePaths.length === 1"
                    class="item-image md:w-2/2 img-responsive"
                    :alt="imageTitle"
                    :src="imagePaths[0]"
                    data-test="modal-content-image"
                >
            </div>
            <button
                type="button"
                :aria-label="$t('modal.close')"
                class="btn-close modal-close p-2 z-50 bg-white"
                data-test="modal-close-button"
                @click="closeModal"
            >
                <icon-modal-close
                    class="modal-close-icon icon-small"
                />
            </button>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import { onMounted, onUnmounted } from 'vue';
    import IconModalClose from '~/components/icons/IconModalClose.vue';
    import ImageSlider from '~/components/common/ImageSlider.vue';
    import { storeToRefs } from 'pinia';
    import { useModalStore } from '~/stores/modalStore';

    const modalStore = useModalStore();
    const { imageTitle, imagePaths, showModal } = storeToRefs(modalStore);

    const closeModal = () => {
        modalStore.resetModal();
    };

    const handleKeyUp = (event: any) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    };

    onMounted(() => {
        window.addEventListener('keyup', handleKeyUp);
    });

    onUnmounted(() => {
        window.removeEventListener('keyup', handleKeyUp);
    });
</script>

<style lang="scss">

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 51;
}

.modal {
    max-width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px 0.1px rgba(33,37,41,0.05);
}

.modal-open {
    padding-right: 16px;
}

.modal-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 0.375rem;

    &:hover {
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .1);
    }
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    border: 0;
    z-index: 53;
}

.modal .item-image {
    width: 100%;
    max-width: 800px;
    max-height: 88vh;
    object-fit: contain;
}

@media (max-width: 575px) {
    .modal {
        margin: 0.5rem;
    }
}

/* transition slide-up */
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
