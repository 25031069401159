<template>
    <div>
        <div>
            <!-- page header -->
            <page-header
                :is-primary-menu-open="isPrimaryMenuOpen"
                @show-user-dropdown-menu-changed="showUserDropdownMenuChanged"
            />
            <div class="page-wrapper bg-neutral-100 pb-14 pt-[64px] md:pt-[92px]">
                <page-hero />
                <div class="container mt-[-62px]">
                    <primary-page-menu
                        :is-header-menu-open="isUserDropdownMenuOpen || isStaticDropdownMenuOpen"
                        @show-primary-menu-changed="showPrimaryMenuChanged"
                    />
                </div>
                <div class="main-section container md:flex gap-5 my-5 md:mt-7">
                    <div
                        v-if="liteSidebarStore.hasLiteSidebar"
                        class="lite-sidebar-wrapper max-w-[240px] min-w-[240px] lg:max-w-[305px] lg:min-w-[305px]"
                    >
                        <lite-sidebar
                            class="hidden md:block"
                        />
                    </div>

                    <div class="content-section flex flex-col self-start w-full mx-auto overflow-hidden">
                        <bread-crumbs />

                        <!-- sub page menu -->
                        <sub-page-menu />

                        <!-- page content -->
                        <div
                            class="page-content-wrapper"
                            :class="{ 'has-sidebar': liteSidebarStore.hasLiteSidebar }"
                        >
                            <page-content-header
                                :page-title="pageTitle"
                                :page-description="pageDescription"
                                :page-icon="pageIcon"
                            />
                            <timeline-content-loader
                                class="content-loader"
                                data-test="page-content-loader"
                                :visible="navigationStore.isLoading"
                            />
                            <div
                                class="page-content"
                                data-test="page-content"
                            >
                                <slot />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal />
        <div
            v-if="isPrimaryMenuOpen || isUserDropdownMenuOpen || isStaticDropdownMenuOpen || showModal"
            class="overlay z-30"
            :class="{ 'z-50': showModal }"
        />
    </div>
</template>

<script setup lang="ts">
    import PageHeader from '~/components/pageHeader/PageHeader.vue';
    import PageHero from '~/components/pageHeader/PageHero.vue';
    import PrimaryPageMenu from '@/components/pageNavigation/PrimaryPageMenu.vue';
    import SubPageMenu from '@/components/pageNavigation/SubPageMenu.vue';
    import BreadCrumbs from '~/components/pageNavigation/BreadCrumbs.vue';
    import Modal from '~/components/modal/TheModal.vue';
    import LiteSidebar from '~/components/pageFooter/LiteSidebar.vue';
    import { useAccountStore } from '~/stores/accountStore';
    import { useNavigationStore } from '~/stores/navigationStore';
    import { useLiteSidebarStore } from '~/stores/liteSidebarStore';
    import { useModalStore } from '~/stores/modalStore';
    import { useTitle } from '@vueuse/core';
    import { storeToRefs } from 'pinia';
    import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
    const { setLocale } = useI18n();
    import TimelineContentLoader from '~/components/timeline/TimelineContentLoader.vue';
    import PageContentHeader from '~/components/pageHeader/PageContentHeader.vue';

    const route = useRoute();
    const accountStore = useAccountStore();
    const navigationStore = useNavigationStore();
    const liteSidebarStore = useLiteSidebarStore();
    const { state: navigationState } = storeToRefs(navigationStore);
    const pageTitle = ref(navigationState.value.currentPageTitle);
    const pageDescription = ref(navigationState.value.currentPageDescription);
    const pageIcon = ref(navigationState.value.currentPageIconSvg);
    const screenWidth = ref(0);
    const isUserDropdownMenuOpen = ref(false);
    const isStaticDropdownMenuOpen = ref(false);
    const isPrimaryMenuOpen = ref(false);
    const modalStore = useModalStore();
    const { showModal } = storeToRefs(modalStore);

    setLocale(accountStore.state.locale);

    const title = computed(() => {
        let title = accountStore.state.name;

        if (navigationState.value.currentPageTitle) {
            title = title + ' - ' + navigationState.value.currentPageTitle;
        }

        return title;
    });

    useTitle(title);

    const showUserDropdownMenuChanged = (isOpen: boolean) => {
        isUserDropdownMenuOpen.value = isOpen;
    };

    const showPrimaryMenuChanged = (isOpen: boolean) => {
        isPrimaryMenuOpen.value = isOpen;
    };

    const setPageWrapperDynamicStyles = () => {
        const navbarHeight = document.querySelector('nav')?.clientHeight || 0;
        const pageWrapper: HTMLElement | null = document.querySelector('.page-wrapper');
        if (pageWrapper) {
            pageWrapper.style.paddingTop = `${navbarHeight}px`;
            pageWrapper.style.minHeight = `${window.innerHeight}px`;
        }
    };

    const updateScreenWidth = () => {
        screenWidth.value = window.innerWidth;
        setPageWrapperDynamicStyles();
    };

    watch(
        () => navigationState.value.currentPageTitle,
        newPageTitle => {
            pageTitle.value = newPageTitle || '';
        }
    );

    watch(
        () => navigationState.value.currentPageDescription,
        newPageDescription => {
            pageDescription.value = newPageDescription || '';
        }
    );

    watch(
        () => navigationState.value.currentPageIconSvg,
        newPageIcon => {
            pageIcon.value = newPageIcon || null;
        }
    );

    watch(
        () => route.path,
        newPath => {
            // react also if user redirects to home page using '/' links
            if (newPath === '/') {
                navigationStore.setPageDetails(
                    navigationState.value.navItems[0].label || '',
                    navigationState.value.navItems[0].description || '',
                    navigationState.value.navItems[0].hiddenHeader || false,
                    navigationState.value.navItems[0].icon || null);
                pageTitle.value = navigationState.value.currentPageTitle;
                pageDescription.value = navigationState.value.currentPageDescription;
                pageIcon.value = navigationState.value.currentPageIconSvg;
            }
        }
    );

    onMounted(() => {
        updateScreenWidth();

        window.addEventListener('resize', updateScreenWidth);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateScreenWidth);
    });
</script>

<style scoped lang="scss">
    .page-content.has-sidebar {
        max-width: 634px;
    }

    .page-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Number of lines to display */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(2px);
    }
</style>
