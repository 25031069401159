<template>
    <div
        v-if="content"
        ref="sidebarElement"
        class="lite-sidebar my-4 md:mt-0 md:my-7"
        :style="`
            position:${ sidebarPosition };
            top:${ sidebarTop };
            left:${ sidebarLeft };
            width:${ sidebarWidth };
            max-height:${ sidebarMaxHeight };
        `"
        v-html="content"
    />
</template>

<script setup lang="ts">
    import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
    import { useLiteSidebarStore } from '~/stores/liteSidebarStore';
    import {
        SIDEBAR_FIXED_HEIGHT_REDUCTION,
        SIDEBAR_FIXED_LEFT_REDUCTION,
        SIDEBAR_FIXED_TOP_POSITION, SIDEBAR_FIXED_WIDTH_REDUCTION
    } from '~/utils/constants';
    import { useRoute } from 'vue-router';
    import { storeToRefs } from 'pinia';
    import { useNavigationStore } from '~/stores/navigationStore';

    const { content } = useLiteSidebarStore().state;

    const sidebarElement = ref<HTMLElement | null>(null);
    const sidebarPosition = ref<string>('relative');
    const sidebarTop = ref<string>('auto');
    const sidebarLeft = ref<string>('auto');
    const sidebarWidth = ref<string>('auto');
    const sidebarMaxHeight = ref<string>('auto');
    const route = useRoute();
    const { isLoading } = storeToRefs(useNavigationStore());

    const handleScroll = () => {
        handleFixedSidebar();
    };

    const handleResize = () => {
        const screenHeight = window.innerHeight;
        const sidebarHeight = sidebarElement.value?.scrollHeight;
        const availableHeight = screenHeight - SIDEBAR_FIXED_HEIGHT_REDUCTION;
        const pageContentHeight = document.querySelector('.content-section')?.getBoundingClientRect().height;
        const isContentSmallerThanAvailableHeight = pageContentHeight && (pageContentHeight < availableHeight);

        if (!sidebarHeight || isContentSmallerThanAvailableHeight) {
            return;
        }

        sidebarMaxHeight.value = availableHeight > sidebarHeight ? 'none' : `${ availableHeight }px`;

        if (window.innerWidth < 767) {
            // ensuring that the whole sidebar will be displayed on small screens
            sidebarMaxHeight.value = 'auto';
        }

        handleFixedSidebar();
    };

    const handleRouteChange = () => {
        sidebarPosition.value = 'relative';
        sidebarTop.value = 'auto';
        sidebarLeft.value = 'auto';
        sidebarWidth.value = 'auto';

        sidebarMaxHeight.value = 'none';
    };

    const handleFixedSidebar = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const sidebarHeight = sidebarElement.value?.getBoundingClientRect().height;
        const mainSectionLeft = document.querySelector('.main-section')?.getBoundingClientRect().left;
        const contentSectionTop = document.querySelector('.content-section')?.getBoundingClientRect().top;
        const contentSectionLeft = document.querySelector('.content-section')?.getBoundingClientRect().left;
        const contentSectionHeight = document.querySelector('.content-section')?.getBoundingClientRect().height;

        if (
            !sidebarHeight
            || !mainSectionLeft
            || !contentSectionTop
            || !contentSectionLeft
            || !contentSectionHeight
        ) {
            return;
        }

        fixSidebar(
            scrollTop,
            sidebarHeight,
            mainSectionLeft,
            contentSectionTop,
            contentSectionLeft,
            contentSectionHeight
        );
    };

    const fixSidebar = (
        scrollTop: number,
        sidebarHeight: number,
        mainSectionLeft: number,
        contentSectionTop: number,
        contentSectionLeft: number,
        contentSectionHeight: number
    ) => {
        if (contentSectionHeight < sidebarHeight) {
            return;
        }

        const scrolledBeyondOffset = scrollTop > contentSectionTop + scrollTop - SIDEBAR_FIXED_TOP_POSITION;
        const isSmallScreen = window.innerWidth < 767;

        sidebarPosition.value = 'relative';
        sidebarTop.value = 'auto';
        sidebarLeft.value = 'auto';
        sidebarWidth.value = 'auto';

        if (scrolledBeyondOffset && !isSmallScreen) {
            sidebarPosition.value = `fixed`;
            sidebarTop.value = `${ SIDEBAR_FIXED_TOP_POSITION }px`;
            sidebarLeft.value = `${ mainSectionLeft + SIDEBAR_FIXED_LEFT_REDUCTION }px`;
            sidebarWidth.value = `${ contentSectionLeft - mainSectionLeft - SIDEBAR_FIXED_WIDTH_REDUCTION }px`;
        }
    };

    watch(
        () => route.path,
        () => {
            handleRouteChange();
        }
    );

    watch(
        () => isLoading.value,
        async (isLoading) => {
            if (!isLoading) {
                await nextTick();
                handleScroll();
                handleResize();
            }
        },
    );

    onMounted(() => {
        window.addEventListener('scroll', () => handleScroll());
        window.addEventListener('resize', () => handleResize());
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', () => handleScroll());
        window.removeEventListener('resize', () => handleResize());
    });
</script>

<style scoped>
    .lite-sidebar :deep(h1) {
      @apply text-xl;
      @apply font-semibold;
      @apply py-2;
    }

    .lite-sidebar :deep(h2) {
      @apply text-lg;
      @apply font-semibold;
      @apply py-2;
    }

    .lite-sidebar :deep(h3) {
      @apply text-base;
      @apply py-2;
    }

    .lite-sidebar :deep(p) {
      @apply py-1;
    }

    .lite-sidebar :deep(code) {
      word-break: break-word;
    }

    .lite-sidebar :deep(.line) {
      @apply flex;
      @apply flex-wrap;
    }

    .lite-sidebar {
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #e5e5e5 #e5e5e500;
    }

    .lite-sidebar::-webkit-scrollbar {
        width: 12px;
    }

    .lite-sidebar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }

    .lite-sidebar::-webkit-scrollbar-thumb {
        background-color: #E5E5E5;
        border-radius: 10px;
        border: 5px solid #E5E5E5;
        transition: background-color 0.5s ease-out;
    }
</style>
