<template>
    <div
        v-if="showSubMenu"
        class="submenu-grid flex flex-col lg:grid mb-5"
        :class="{ 'has-sidebar': liteSidebarStore.hasLiteSidebar }"
    >
        <NuxtLink
            v-for="item in itemsToDisplay"
            :key="navigationStore.currentNavigationLevel === 2 ? 'secondary-' + item.slug : 'tertiary-' + item.slug"
            :href="item.link"
            :target="isCustomItem(item.type) ? '_blank' : null"
            :rel="isCustomItem(item.type) ? 'noopener external' : null"
            class="sub-page-menu-link flex grow basis-0 items-center rounded-md"
            :class="{ 'active': isActiveItem(item.link) }"
            @click="handleSubMenuItemClick(item)"
        >
            <div class="flex items-center justify-between w-full">
                <div class="flex items-center overflow-hidden">
                    <div class="menu-icon-wrapper flex items-center justify-center rounded-full mr-2">
                        <span
                            class="icon-sub-menu-item"
                            v-html="item.icon"
                        />
                    </div>
                    <span class="sub-page-menu-link-label">{{ item?.label }}</span>
                </div>
                <icon-right-arrow class="right-icon icon-extra-small ml-2.5 mr-3.5" />
            </div>
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useNavigationStore } from '~/stores/navigationStore';
    import { useAccountStore } from '~/stores/accountStore';
    import { useLiteSidebarStore } from '~/stores/liteSidebarStore';
    import { useRoute } from 'vue-router';
    import IconRightArrow from '~/components/icons/IconRightArrow.vue';
    import { MENU_LEVEL_THRESHOLD } from '~/utils/constants';
    import type { INavItem } from '~/types/types';
    import type { EMenuType } from '~/types/enums';

    const { buttonColor, buttonTextColor } = useAccountStore().state;
    const navigationStore = useNavigationStore();
    const liteSidebarStore = useLiteSidebarStore();
    const route = useRoute();

    const hasChildren = computed(() => {
        return navigationStore.getActiveNavItemForGivenLevel(navigationStore.currentNavigationLevel)?.items.length > 0;
    });

    const previousItemChildItems = computed(() => {
        if (navigationStore.currentNavigationLevel > 1) {
            return navigationStore.getActiveNavItemForGivenLevel(navigationStore.currentNavigationLevel - 1).items || [];
        }
        return [];
    });

    const showSubMenu = computed(() => {
        if (!hasChildren.value) {
            return previousItemChildItems.value.length > 0;
        }
        return navigationStore.getLastActiveNavItem?.items.length > 0;
    });

    const itemsToDisplay = computed(() => {
        const currentNavLevelChildItems = navigationStore.getLastActiveNavItem?.items;

        // in case we are on the last level of navigation, display the child item
        // of the last but one active item and the active item in stays highlighted
        return currentNavLevelChildItems.length > 0 ? currentNavLevelChildItems : previousItemChildItems.value;
    });

    const isCustomItem = (type: EMenuType) => {
        return type === 'custom';
    };

    const isActiveItem = (itemLink: string) => {
        return route.path === itemLink;
    };

    const handleSubMenuItemClick = (item: INavItem) => {
        const newNavigationLevel = navigationStore.currentNavigationLevel === MENU_LEVEL_THRESHOLD
            ? navigationStore.currentNavigationLevel
            : navigationStore.currentNavigationLevel + 1;

        if (item.type !== 'custom' && !isActiveItem(item.link)) {
            navigationStore.setActiveNavItems(item, newNavigationLevel);
        }
    };
</script>

<style scoped lang="scss">
    $buttonColor: v-bind(buttonColor);
    $buttonTextColor: v-bind(buttonTextColor);

    .sub-page-menu-link {
        @apply bg-white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        transition: all 0.15s ease;
        padding: 5px 5px 5px 9px;
        height: 50px;

        @media (min-width: 768px) {
            &:hover {
                background-color: $buttonColor;
                color: $buttonTextColor;

                .right-icon {
                    color: $buttonTextColor;
                    transform: translateX(3px);
                }
            }
        }
    }

    .sub-page-menu-link.router-link-active:hover {
        .right-icon {
            color: $buttonColor;
        }
    }

    .sub-page-menu-link-label {
        font-size: 0.825rem;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .router-link-active {
        background-color: $buttonColor;
        color: $buttonTextColor;
    }

    .submenu-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 6px;
    }

    .submenu-grid.has-sidebar {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .menu-icon-wrapper {
        @apply bg-white;
        width: 2.4rem;
        height: 2.4rem;

        @media (min-width: 768px) {
            width: 2rem;
            height: 2rem;
        }
    }

    .icon-sub-menu-item {
        display: contents;
        box-sizing: border-box;
    }

    .right-icon {
        color: $buttonColor;
        transition: all 0.15s ease;
        min-width: 14px;
        max-width: 14px;
    }
</style>
