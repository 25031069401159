<template>
    <div class="breadcrumbs flex gap-3 items-center justify-between bg-neutral-200 rounded-md py-1.5 pl-4 pr-1.5 font-semibold min-h-[40px] mb-5 w-full">
        <div class="w-full overflow-hidden">
            <div class="flex items-center gap-3 breadcrumbs-wrapper">
                <div
                    v-for="(item, index) in breadcrumbItems"
                    :key="item.label"
                    class="flex items-center"
                >
                    <NuxtLink
                        v-if="showBreadcrumbItem(index)"
                        :href="item.link"
                        class="breadcrumb-item text-xs"
                        :class="{ 'no-pointer-events': index === breadcrumbItems.length - 1 }"
                        data-test="breadcrumb-item"
                        @click="handleBreadcrumbItemClick(index)"
                    >
                        {{ truncateLabel(item.label) }}
                    </NuxtLink>
                    <span
                        v-if="showThreeDots(index)"
                        class="breadcrumb-three-dots"
                    >...</span>
                    <icon-simple-arrow
                        v-if="showChevron(index)"
                        custom-color="#c3c3c3"
                        :class="{ 'breadcrumb-chevron': true, 'ml-3': showMargin(index) }"
                    />
                </div>
            </div>
        </div>
        <NuxtLink
            class="back-button bg-white p-2"
            :class="{ 'disabled': isBackButtonDisabled }"
            :href="backLink"
            data-test="breadcrumb-back-button"
            @click="handleBackButtonCLick"
        >
            <icon-menu-back :custom-color="isBackButtonDisabled ? '#a0aec0' : buttonColor" />
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
    import { type Ref, ref, watch, onMounted, computed } from 'vue';
    import IconSimpleArrow from '~/components/icons/IconSimpleArrow.vue';
    import IconMenuBack from '~/components/icons/IconMenuBack.vue';
    import { useAccountStore } from '~/stores/accountStore';
    import { useNavigationStore } from '~/stores/navigationStore';
    import { storeToRefs } from 'pinia';
    import type { IBreadcrumbItem } from '~/types/types';
    import type { INavigationStore } from '~/types/store.types';

    const { name: accountName, buttonColor } = useAccountStore().state;
    const { state: navigationState } = storeToRefs(useNavigationStore());
    const store = useNavigationStore();

    const windowWidth = ref(0);
    const breadcrumbItems: Ref<IBreadcrumbItem[]> = ref([
        {
            label: accountName,
            link: '/',
        },
        {
            label: store.getActiveNavItemForGivenLevel(1)?.label || navigationState.value.navItems[0].label,
            link: '/'
        },
    ]);

    const isSmallScreen = computed(() => {
        return windowWidth.value < 600;
    });

    const breadcrumbCount = computed(() => {
        return breadcrumbItems.value.length;
    });

    const isBackButtonDisabled = computed(() => {
        return store.currentNavigationLevel < 2 && breadcrumbItems.value[1]?.link === '/';
    });

    const backLink = computed(() => {
        if (store.currentNavigationLevel > 1) {
            const lastButOneActiveItem = store.getActiveNavItemForGivenLevel(store.currentNavigationLevel - 1);
            return lastButOneActiveItem?.link || '/';
        }

        return '/';
    });

    const isFirstItem = (index: number) => {
        return index === 0;
    };

    const isLastItem = (index: number) => {
        return index === breadcrumbCount.value -1;
    };

    const isLastItemButOne = (index: number) => {
        return index === breadcrumbCount.value -2;
    };

    const shouldShortenBreadcrumbs = () => {
        return isSmallScreen.value && breadcrumbCount.value > 2;
    };

    const setWindowWidth = () => {
        windowWidth.value = window.innerWidth;
    };

    const showBreadcrumbItem = (index: number) => {
        if (!isSmallScreen.value) {
            return true;
        }

        return isFirstItem(index) || isLastItem(index);
    };

    const showChevron = (index: number) => {
        if (shouldShortenBreadcrumbs()) {
            return isFirstItem(index) || isLastItemButOne(index);
        }

        return !isLastItem(index);
    };

    const showThreeDots = (index: number) => {
        return shouldShortenBreadcrumbs() && index === 1;
    };

    const showMargin = (index: number) => {
        return !(shouldShortenBreadcrumbs() && index === 2);
    };

    const prepareBreadcrumbItems = (newState: INavigationStore) => {
        breadcrumbItems.value.splice(1);

        if (newState.activeNavItems.length > 0) {
            newState.activeNavItems.forEach((item) => {
                breadcrumbItems.value.push({
                    label: item.label || '',
                    link: item.link || '/'
                });
            });
        } else {
            if (newState.currentPageTitle) {
                breadcrumbItems.value.push({
                    label: newState.currentPageTitle,
                    link: '/'
                });
            }
        }

    };

    const handleBreadcrumbItemClick = (index: number) => {
        if (index > 0) {
            const currentActiveNavItem = store.getActiveNavItemForGivenLevel(index);
            store.setActiveNavItems(currentActiveNavItem, index, true);
            store.setPageDetails(currentActiveNavItem?.label || '', currentActiveNavItem?.description, currentActiveNavItem?.icon);
        } else {
            const primaryNavItem = store.state.navItems[0];
            store.setActiveNavItems(primaryNavItem, 1, true);
            store.setPageDetails(primaryNavItem.label, primaryNavItem.description, primaryNavItem.icon);
        }
    };

    const handleBackButtonCLick = () => {
        if (store.currentNavigationLevel > 1) {
            const lastButOneActiveItem = store.getActiveNavItemForGivenLevel(store.currentNavigationLevel - 1);
            store.setPageDetails(lastButOneActiveItem?.label || '', lastButOneActiveItem?.description, lastButOneActiveItem?.icon);
            store.setActiveNavItems(lastButOneActiveItem, store.currentNavigationLevel - 1, true);
        } else {
            store.setActiveNavItems(store.state.navItems[0], 1, true);
        }
    };

    const truncateLabel = (label: string) => {
        return label.length > 20 ? label.substring(0, 20) + '...' : label;
    };

    watch(
        () => navigationState.value,
        newState => {
            prepareBreadcrumbItems(newState);
            setWindowWidth();
        },
        { deep: true }
    );

    onMounted(() => {
        prepareBreadcrumbItems(navigationState.value);
        setWindowWidth();

        window.addEventListener('resize', () => setWindowWidth());
    });
</script>

<style scoped lang="scss">
    .breadcrumbs-wrapper {
        width: fit-content;
    }

    .breadcrumb-item {
        line-height: 1.2;
        white-space: nowrap;
        transition: all 0.15s ease;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            @apply text-neutral-500;
        }
    }

    .no-pointer-events {
        pointer-events: none;
    }

    .back-button {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        @apply rounded-md;

        &:hover {
            box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .1);
        }
    }

    .disabled {
        @apply bg-neutral-100;
        pointer-events: none;

        &:hover {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        }
    }
</style>
