<template>
    <div
        class="primary-page-menu w-100 gap-1.5"
    >
        <div class="relative">
            <div
                class="grid gap-[6px] pr-[12px] grid-columns overflow-hidden max-h-[130px] w-100"
            >
                <NuxtLink
                    v-for="menuItem in previewItems"
                    :key="menuItem.label"
                    :href="menuItem.link"
                    :target="isCustomItem(menuItem.type) ? '_blank' : null"
                    :rel="isCustomItem(menuItem.type) ? 'noopener external' : null"
                    data-test="page-menu-link"
                    class="page-menu-link flex grow basis-0 flex-col items-center py-3 rounded-md h-[130px]"
                    :class="{ 'active': isActiveNavItem(menuItem) }"
                    @click="handleActiveNavItem(menuItem)"
                >
                    <div class="menu-icon-wrapper flex items-center justify-center rounded-full">
                        <span
                            class="icon-large icon-menu-item"
                            data-test="page-menu-link-icon"
                            v-html="menuItem.icon"
                        />
                    </div>
                    <span
                        class="page-menu-link-label font-semibold text-center px-2 flex grow items-center text-sm"
                        :class="{ 'vertically-centered': shouldCenterVertically(menuItem.label) }"
                        data-test="page-menu-link-label"
                    >
                        {{ menuItem.label }}
                    </span>
                </NuxtLink>
            </div>
            <div
                v-if="showMenuModal"
                class="flex flex-col primary-menu-modal absolute bg-neutral-100 ml-[-12px] mt-[-12px] rounded-md top-0 z-40"
            >
                <div class="grid gap-[6px] grid-columns overflow-hidden p-[12px]">
                    <NuxtLink
                        v-for="menuItem in allMenuItems"
                        :key="menuItem.label"
                        :href="menuItem.link"
                        :target="isCustomItem(menuItem.type) ? '_blank' : null"
                        :rel="isCustomItem(menuItem.type) ? 'noopener external' : null"
                        class="page-menu-modal-link flex grow basis-0 flex-col items-center py-3 rounded-md h-[130px]"
                        :class="{ 'active': isActiveNavItem(menuItem) }"
                        @click="handleActiveNavItem(menuItem)"
                    >
                        <div class="menu-icon-wrapper flex items-center justify-center rounded-full">
                            <span
                                class="icon-large icon-menu-item"
                                v-html="menuItem.icon"
                            />
                        </div>
                        <span
                            class="page-menu-modal-link-label font-semibold text-center px-2 flex grow items-center text-sm"
                            :class="{ 'vertically-centered': shouldCenterVertically(menuItem.label) }"
                        >
                            {{ menuItem.label }}
                        </span>
                    </NuxtLink>
                </div>
                <lite-sidebar
                    v-if="liteSidebarStore.hasLiteSidebar"
                    class="lite-sidebar block md:hidden mt-7 md:mt-0 mx-[12px]"
                />
            </div>
        </div>
        <button
            v-if="allMenuItems && allMenuItems.length > 0"
            id="menu-more"
            class="p-3.5 h-[50px] translate-y-8"
            :class="{
                'bg-white': !showMenuModal,
                'bg-neutral-100': showMenuModal,
                'shadow-big': showMenuModal,
                'z-40': !isHeaderMenuOpen,
                'z-10': isHeaderMenuOpen
            }"
            data-bs-toggle="modal"
            aria-haspopup="true"
            aria-expanded="false"
            @click="primaryMenuButtonClicked"
        >
            <icon-menu-more
                v-if="!showMenuModal"
                class="menu-more-icon icon-small"
            />
            <icon-menu-close
                v-if="showMenuModal"
                class="menu-close-icon icon-small"
            />
        </button>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, onMounted, watch } from 'vue';
    import IconMenuMore from '~/components/icons/IconMenuMore.vue';
    import IconMenuClose from '~/components/icons/IconMenuClose.vue';
    import { useAccountStore } from '~/stores/accountStore';
    import { useNavigationStore } from '~/stores/navigationStore';
    import { useLiteSidebarStore } from '~/stores/liteSidebarStore';
    import { storeToRefs } from 'pinia';
    import { getNavItemsGroupToDisplay } from '~/utils/helpers';
    import LiteSidebar from '~/components/pageFooter/LiteSidebar.vue';
    import { useRoute } from 'vue-router';
    import type { INavItem } from '~/types/types';

    defineProps({
        isHeaderMenuOpen: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const { buttonColor, buttonTextColor } = useAccountStore().state;
    const liteSidebarStore = useLiteSidebarStore();
    const emit = defineEmits(['showPrimaryMenuChanged']);

    const navStore = useNavigationStore();
    const reactiveNavStore = storeToRefs(useNavigationStore());
    const allMenuItems = reactiveNavStore.state.value.navItems;
    const activeNavItem = navStore.getActiveNavItemForGivenLevel(1);
    const activeNavItemSlug = ref('');
    const showMenuModal = ref(false);
    const screenWidth = ref(0);

    const previewItems = computed(() => {
        return getNavItemsGroupToDisplay(screenWidth.value, allMenuItems, navStore);
    });

    const isCustomItem = (type: string) => {
        return type === 'custom';
    };

    const isActiveNavItem = (menuItem: INavItem) => {
        return menuItem.type !== 'custom' && activeNavItemSlug.value === menuItem.slug;
    };

    const handleActiveNavItem = (menuItem: INavItem): void => {
        if (menuItem.type !== 'custom') {
            navStore.setActiveNavItems(menuItem, 1);
            activeNavItemSlug.value = menuItem.slug;
        }

        showMenuModal.value = false;
        emit('showPrimaryMenuChanged', showMenuModal.value);
    };

    const primaryMenuButtonClicked = () => {
        showMenuModal.value = !showMenuModal.value;
        emit('showPrimaryMenuChanged', showMenuModal.value);
    };

    const setScreenWidth = () => {
        screenWidth.value = window.innerWidth;
    };

    const shouldCenterVertically = (label: string) => {
        const firstWord = label.split(' ')[0];
        return label.length < 17 || firstWord.length > 15;
    };

    const setActiveNavItemSlug = () => {
        let isInNavItems = true;
        const parts = useRoute()?.path.split('/');

        if (parts && parts.length > 0) {
            const routeSlug = parts[parts.length - 1];
            isInNavItems = reactiveNavStore.state.value.navItems.some(obj => obj.slug.includes(routeSlug));
        }

        if (!isInNavItems) {
            // in case there is a page that is not in menu items
            return activeNavItemSlug.value = '';
        } else if (activeNavItem) {
            return activeNavItemSlug.value = activeNavItem.slug;
        } else {
            return activeNavItemSlug.value = 'dashboard';
        }
    };

    watch(
        () => reactiveNavStore.state.value.activeNavItems[0],
        newActiveItem => {
            if (newActiveItem?.slug === 'dashboard') {
                activeNavItemSlug.value = 'dashboard';
            }

            setActiveNavItemSlug();
            handleActiveNavItem(newActiveItem);
        },
    );

    watch(
        () => showMenuModal.value,
        newState => {
            if (screen.width >= 760) {
                if (newState) {
                    document.documentElement.classList.add('modal-open');
                    document.body.style.width = '100vw';
                    document.getElementsByClassName('page-wrapper')[0].classList.add('modal-open');
                } else {
                    document.documentElement.classList.remove('modal-open');
                    document.body.style.width = '';
                    document.getElementsByClassName('page-wrapper')[0].classList.remove('modal-open');
                }
            }
        }
    );

    onMounted(() => {
        setActiveNavItemSlug();
        setScreenWidth();

        window.addEventListener('resize', () => setScreenWidth());
    });
</script>

<style scoped lang="scss">
    $buttonColor: v-bind(buttonColor);
    $buttonTextColor: v-bind(buttonTextColor);

    .primary-page-menu {
        display: grid;
        grid-template-columns: auto 50px;
    }

    .page-menu-link,
    .page-menu-modal-link {
        @apply bg-white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        transition: all 0.15s ease;

        &:hover {
            background-color: $buttonColor;
            color: $buttonTextColor;
        }
    }

    .page-menu-link,
    .page-menu-modal-link {
        overflow: hidden;
    }

    .page-menu-link-label,
    .page-menu-modal-link-label {
        line-height: 1.2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Number of lines to display */
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: -0.5px;
    }

    .vertically-centered {
        display: flex;
        align-items: center;
    }

    .active,
    .router-link-active {
        background-color: $buttonColor;
        color: $buttonTextColor;
    }

    .menu-icon-wrapper {
        @apply bg-white;
        width: 3.15rem;
        height: 3.15rem;
        margin-bottom: 0.5rem;
        padding: 0.7rem;
    }

    .icon-menu-item {
        display: contents;
        box-sizing: border-box;
    }

    #menu-more {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        @apply rounded-md;

        &:hover {
            box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .1);
        }
    }

    .primary-menu-modal {
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .1);
        backdrop-filter: blur(5px);
        width: calc(100% + 12px);
    }

    .shadow-big {
        box-shadow: 0 1px 20px 0 rgba(0,0,0,.1);
    }

    .grid-columns {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @media (min-width: 540px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media (min-width: 768px) {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        @media (min-width: 992px) {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }
    }

    /* we will explain what these classes do next! */
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>
