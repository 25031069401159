<template>
    <div class="hero-section mx-auto">
        <img
            :src="background"
            alt=""
            class="hero-image md:rounded-bl-md md:rounded-br-md w-full h-72 md:h-80 object-cover"
        >
    </div>
</template>

<script setup lang="ts">
    import { useAccountStore } from '~/stores/accountStore';

    const { background } = useAccountStore().state;
</script>

<style scoped lang="scss">
    .hero-section {
        max-width: 1272px;
    }

    .hero-image {
        height: 300px;

        @media (max-width: 767px) {
            height: 280px;
        }
    }
</style>
