<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            :fill="buttonColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.90222 0C0.851654 0 0 0.851653 0 1.90222V7.18869C0 8.23925 0.851653 9.09091 1.90222 9.09091H7.18869C8.23925 9.09091 9.09091 8.23926 9.09091 7.18869V1.90222C9.09091 0.851654 8.23926 0 7.18869 0H1.90222ZM2.47327 1.36364C1.86043 1.36364 1.36364 1.86044 1.36364 2.47327V6.61764C1.36364 7.23047 1.86044 7.72727 2.47327 7.72727H6.61764C7.23047 7.72727 7.72727 7.23047 7.72727 6.61764V2.47327C7.72727 1.86043 7.23047 1.36364 6.61764 1.36364H2.47327Z"
        />
        <path
            :fill="buttonColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8113 0C11.7607 0 10.9091 0.851653 10.9091 1.90222V7.18869C10.9091 8.23925 11.7607 9.09091 12.8113 9.09091H18.0978C19.1483 9.09091 20 8.23926 20 7.18869V1.90222C20 0.851654 19.1483 0 18.0978 0H12.8113ZM13.3824 1.36364C12.7695 1.36364 12.2727 1.86044 12.2727 2.47327V6.61764C12.2727 7.23047 12.7695 7.72727 13.3824 7.72727H17.5267C18.1396 7.72727 18.6364 7.23047 18.6364 6.61764V2.47327C18.6364 1.86043 18.1396 1.36364 17.5267 1.36364H13.3824Z"
        />
        <path
            :fill="buttonColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.90222 10.9092C0.851654 10.9092 0 11.7608 0 12.8114V18.0979C0 19.1484 0.851653 20.0001 1.90222 20.0001H7.18869C8.23925 20.0001 9.09091 19.1484 9.09091 18.0979V12.8114C9.09091 11.7608 8.23926 10.9092 7.18869 10.9092H1.90222ZM2.47327 12.2728C1.86043 12.2728 1.36364 12.7696 1.36364 13.3824V17.5268C1.36364 18.1397 1.86044 18.6365 2.47327 18.6365H6.61764C7.23047 18.6365 7.72727 18.1397 7.72727 17.5268V13.3824C7.72727 12.7696 7.23047 12.2728 6.61764 12.2728H2.47327Z"
        />
        <path
            :fill="buttonColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8113 10.9092C11.7607 10.9092 10.9091 11.7608 10.9091 12.8114V18.0979C10.9091 19.1484 11.7607 20.0001 12.8113 20.0001H18.0978C19.1483 20.0001 20 19.1484 20 18.0979V12.8114C20 11.7608 19.1483 10.9092 18.0978 10.9092H12.8113ZM13.3824 12.2728C12.7695 12.2728 12.2727 12.7696 12.2727 13.3824V17.5268C12.2727 18.1397 12.7695 18.6365 13.3824 18.6365H17.5267C18.1396 18.6365 18.6364 18.1397 18.6364 17.5268V13.3824C18.6364 12.7696 18.1396 12.2728 17.5267 12.2728H13.3824Z"
        />
    </svg>
</template>

<script setup>
    import { useAccountStore } from '~/stores/accountStore.ts';

    const { buttonColor } = useAccountStore().state;
</script>
